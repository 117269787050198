import React from 'react'
import styled from 'styled-components'
import theme from '../../theme'

const ListItem = styled.li`
  width: 100%;
  a {
    font-weight: 500;
    color: ${theme.colors.grey.dark};
  }
`

export const NavLink = ({children, className}) => <ListItem className={className}>{children}</ListItem>
export default NavLink