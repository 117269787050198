let theme = {
  colors: {
      purple: "#000e60",
      red: "#d11c32",
      green: "#7cbc39",
      lightergreen: "#96cd5b",
      lightgreen: "#afd982",
      grey: {
        extralight: '#F1F1F5',
        light: '#D8D8E2',
        medium: '#85858F',
        dark: '#292930'
      }
    }
}

export default theme