import { css } from "styled-components";

let theme = {
  breakpoints: {
      mobile: 480,
      tablet: 768,
      desktop: 1380 // 1200
    }
}

theme.media = {}
Object.keys(theme.breakpoints).forEach(bp => {
  theme.media[bp] = (...args) => css`
    @media only screen and (max-width: ${theme.breakpoints[bp]}px) {
      ${css(...args)}
    }
  `;
});

theme.media.min = {}
Object.keys(theme.breakpoints).forEach(bp => {
  theme.media.min[bp] = (...args) => css`
    @media only screen and (min-width: ${theme.breakpoints[bp] + 1}px) {
      ${css(...args)}
    }
  `;
});

export default theme