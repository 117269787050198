import React from 'react'
import styled, { createGlobalStyle, css }  from "styled-components"
import { withScrollCollapse } from '../hocs/with-scroll-collapse.hoc'
import useBreakpoints from '../hooks/use-breakpoints.hook'
import { isCollapsedContext } from '../contexts/navigation.context'
import theme from '../../theme'

const GlobalStyle = createGlobalStyle`
  ${theme.media.min.tablet`
    body {
      padding-top: 90px;
    } 
  `}
`

const NavbarDesktop = styled.header`
  background: white;
  /* TODO  Codewiederholung */
  /*box-shadow: 0px 4px 4px #29293033; */
  border: 1px solid ${theme.colors.grey.light};
  border-top: none;

  border-radius: 35px;
  box-sizing: content-box;
  transition: width .5s, height .5s, right .5s, top .5s, padding .5s, background .5s;
  z-index: 2;
  width: 100%;
  height: 90px;
  padding: 0px 35px;
  position: fixed;
  right: -35px;
  top: 0;

`

const NavbarTablet = withScrollCollapse(isCollapsedContext, styled(NavbarDesktop)`
  ${theme.media.tablet`
    height: 60px;

    ${props => props.isCollapsed && css`
      width: 60px;
      top: -10px;
      right: -10px;
      padding: 10px 10px 0 0;
      border-radius: 50%;
      transition: width .5s, height .5s, right .5s, top .5s, padding .5s, border-radius 0s .5s;
    `}

    ${props => props.isOpen && css`
      width: 100vw;
      height: 100vh;
      top: -70vmax;
      right: -70vmax;
      padding: 70vmax 70vmax calc(100vmax + 100vw) calc(100vmax + 100vh);
    `}
  `}
`)

export const Navbar = ({children, ...props}) => {
  const isBreakpoint = useBreakpoints();

  return (
    <NavbarTablet {...props}>
      <GlobalStyle />
      {children}
    </NavbarTablet>
  )
  if(isBreakpoint.tablet) {
    return (
      <NavbarTablet {...props}>
        {/* <GlobalStyle /> */}
        {children}
      </NavbarTablet>
    )
  } else {
    return (
      <NavbarDesktop {...props}>
        <GlobalStyle />
        {children}
      </NavbarDesktop>
    )
  }
}