import { useState, useEffect } from 'react';
import useScrollPosition from './use-scroll-position.hook';
import usePreviousState from './use-previous-state.hook';


// TODO suppress warning or add to dependency array ( but in this case the function is called one unneccessary time )
export default function useScrollDirection() {
  const scrollPosition = useScrollPosition();
  const previousScrollPosition = usePreviousState(scrollPosition);
  const [scrollDirection, setScrollDirection] = useState({x: 'unchanged', y: 'unchanged'});
  
  useEffect(() => {
    if(previousScrollPosition) {
      setScrollDirection({
        x: scrollPosition.x < previousScrollPosition.x ? 'left' : scrollPosition.x > previousScrollPosition.x ? 'right' : 'unchanged',
        y: scrollPosition.y < previousScrollPosition.y ? 'up' : scrollPosition.y > previousScrollPosition.y ? 'down' : 'unchanged',
      });
    }
    
  }, [scrollPosition]);

  return scrollDirection;
}