import { useStaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import styled from "styled-components"


const ListItem = styled.li`
  width: 100%;
  img {
    width: 250px;
  }
`
export const LogoTest =  ({className}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <ListItem className={className}>
      <Link to="/" className="link--img">
        <img src={data.logo.publicURL} width="250" height="74" title="Bloomista Garden Logo" alt="Bloomista Garden Logo" />
      </Link>
    </ListItem>
  )
};

export default LogoTest;
