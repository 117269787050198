import React, { useState, useEffect } from "react";
import useScrollDirection from "../hooks/use-scroll-direction.hook";
import useScrollPosition from "../hooks/use-scroll-position.hook";

export const withScrollCollapse = (Context, WrappedComponent, scrollLimit = 20) => {
  /**
   * Return component that sets the isCollapsed attribute to true when 
   * scrolling down (over a certain scrollLimit) and sets it to false when scrolling up
   */

  const getScrollAnchor = (scrollLimit = 0) => {
    if(typeof window !== "undefined") {
      return window.scrollY + scrollLimit;
    } else {
      return 0;
    }
  }
  return (props) => {
    const { y: scrollDirection } = useScrollDirection();
    const { y: scrollPosition } = useScrollPosition();
    const [isCollapsed, setIsCollapsed] = useState(false);
    
    const [scrollAnchor, setScrollAnchor] = useState(0);

    useEffect(() => {
      if(scrollDirection === 'down') {
        setScrollAnchor(Math.max(10, getScrollAnchor(scrollLimit)));
      } else {
        setScrollAnchor(Math.min(document.documentElement.scrollHeight - window.innerHeight -10, getScrollAnchor(-scrollLimit)));
      }
    }, [scrollDirection])

    useEffect(() => {
      if(scrollDirection === 'down' && scrollPosition >= scrollAnchor) {
        setIsCollapsed(true);
      } else if(scrollDirection === 'up' && scrollPosition <= scrollAnchor) {
        setIsCollapsed(false);
      }
    }, [scrollPosition, scrollDirection, scrollAnchor])
    return(
      <Context.Provider value={isCollapsed}>
        <WrappedComponent isCollapsed={isCollapsed} {...props}></WrappedComponent>
      </Context.Provider>
    )
  }
}