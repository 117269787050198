import React from "react"

export const withContextProps = (Context, WrappedComponent) => {
  return (props) => {
    return (
      <Context.Consumer>
        {value =>
          <WrappedComponent {...props} {...value} />
        }
      </Context.Consumer>
      
    )
  }
}