import { useState, useEffect } from 'react';
import theme from '../../theme';
import useWindowDimensions from './use-window-dimensions.hook';

function getBreakpoints(width) {
  const breakpoints = Object.keys(theme.breakpoints).map((breakpoint) => { return {[breakpoint]: width <= theme.breakpoints[breakpoint]}; });
  return Object.assign({}, ...breakpoints);
}

export default function useBreakpoints() {
  const { width } = useWindowDimensions();
  const [isBreakpoint, setIsBreakpoint] = useState(getBreakpoints(width));
  
  useEffect(() => {
    setIsBreakpoint(getBreakpoints(width));
  }, [width]);

  return isBreakpoint;
}