import React from 'react'
import styled from "styled-components"
import Nav from "../molecules/nav.molecule"
// import {LogoTest, SocialLinks, NavLink} from "../atoms"

import {NavLink} from "../atoms/nav-link.atom"
import {SocialLinks} from "../atoms/social-links.atom"
import {LogoTest} from "../atoms/logo-link.atom"

const Navigation = styled(Nav)`
  min-height: 100vh;
`

const Footer = () => {
  return (
  <footer>
    <Navigation>
      <LogoTest />
      <SocialLinks />
      <NavLink><a href="https://bloomista.garden/impressum">Impressum</a></NavLink>
      <NavLink><a href="https://bloomista.garden/datenschutz">Datenschutz</a></NavLink>
      <NavLink><a href="https://bloomista.garden/newsletter">Newsletter</a></NavLink>
    </Navigation>
  </footer>
  )
}

export default Footer
