let theme = {
  squircle: {
    responsive: {
      large: {default: 100, desktop: 80, tablet: 40, mobile: 20},
      medium: {default: 80, desktop: 40, tablet: 20},
      small: {default: 40, desktop: 20},
    },
    fixed: {
      large: {default: 40},
      medium: {default: 20},
      small: {default: 10}
    }
  }
}

export default theme