import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from "react-helmet"
import styled from "styled-components"
import { theme } from "../../theme"
import Header from './navbar.organism'
import Footer from "./footer.organism"
import '../../css/global.sass'

const Layout = ({children, className}) => {
  return (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet htmlAttributes={{lang: 'de'}}>
          <meta charSet="utf-8" />
        </Helmet>
        <Header className={className} siteTitle={data.site.siteMetadata.title} />
        <main className={className}>
          {children}
        </main>
        <Footer className={className} />
      </>
    )}
  />
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// TODO global maxwidth
export const GridLayout = styled(Layout)`
  display: grid;
  max-width: calc(1137px + 40px);
  margin: 0 auto;
  grid-gap: 80px;
  grid-template-columns: repeat(6, 1fr);
  word-break: break-word;
  hyphens: auto;
  padding: 80px 20px 0px;
  ${theme.media.tablet`
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
  `}
  ${theme.media.mobile`
    grid-template-columns: repeat(2, 1fr);
  `}
`


export default Layout
