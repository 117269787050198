import React, { useState } from 'react';

export const withTraversalSkip = (WrappedComponent) => {
  return({leaveParent, returnToParent, ...props}) => {
    const Children = React.Children.map(props.children, (child => React.cloneElement(child, {returnToParent: returnToParent, leaveParent: leaveParent})))

    return(
    <WrappedComponent {...props}>
      {Children}
    </WrappedComponent>
    )
  }
}

export const withTraversal = (WrappedComponent) => {
  return ({leaveParent, returnToParent, isRoot, ...props}) => {
    
    const [status, setStatus] = useState({isVisited: isRoot, isCurrent: isRoot});

    const leave = () => {
      setStatus({isVisited: true, isCurrent: false})
    }

    const returnTo = () => {
      setStatus({isVisited: true, isCurrent: true})
    }

    const enter = () => {
      if(!isRoot) {
        leaveParent();
        setStatus({isVisited: true, isCurrent: true})
      }
    }

    const exit = () => {
      if(!isRoot) {
        returnToParent && returnToParent();
        setStatus({isVisited: false, isCurrent: false})
      }
    }

    const traverse = {
      isRoot,
      enter,
      exit
    }

    const Children = React.Children.map(props.children, (child => React.cloneElement(child, {returnToParent: returnTo, leaveParent: leave})))
    return(
      <WrappedComponent {...traverse} {...status} {...props}>
        {Children}
      </WrappedComponent>
    )
  }
}

