import { useState, useEffect } from 'react';

function getScrollPosition() {
  if(typeof window !== "undefined") {
    const { scrollX: x, scrollY: y } = window;
    return {
      x,
      y
    };
  } else {
    return {
      x: 0,
      y: 0
    }
  }
}

export default function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(getScrollPosition());
  
  useEffect(() => {
    function handleScroll() {
      setScrollPosition(getScrollPosition());
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollPosition;
}