import breakpoints from "./breakpoints.theme"
import colors from "./colors.theme"
import squircle from "./squircle.theme"

export const theme = {
  ...breakpoints,
  ...colors,
  ...squircle
}

export default theme