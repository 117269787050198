import React from 'react'
import styled, {  css }  from "styled-components"
import uuid from "uuid";
import { withTraversal } from '../hocs/with-traversal.hoc'
import { withContextProps } from '../hocs/with-context-props.hoc'
import { isOpenContext } from '../contexts/navigation.context'

import iconArrowRight from '../../images/icon-arrow-right.svg'
import iconArrowLeft from '../../images/icon-arrow-left.svg'
import theme from '../../theme';

const BackButton = styled.button`
  position: relative;
  z-index: 1000;
  line-height: 1.5;
  height: 44px;
  text-align: left;
  padding: 0px 20px 0px 25px;
  margin: 0px 0px 15px 10px;
  border-radius: 44px;
  background: transparent;
  background-image: url(${iconArrowLeft});
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-size: 10px;
  transition: background .5s;
  font-size: 12px;
  :hover {
    background-color: #D8D8E2;
  }
`


export const LevelMenuItem = withContextProps(isOpenContext, styled.li`
  transition: transform .5s ease .1s, opacity .5s ease .1s, background .5s ease;
  padding: 0px 10px 15px;
  border-radius: 7.5px;
  & > *:first-child {
    color: ${theme.colors.grey.dark};
    font-weight: 500;
    word-break: keep-all;
    white-space: nowrap;
    line-height: 1.5;
    width: 100%;
    text-align: left;
    background: transparent;
    padding: 10px 10px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    transition: background .5s ease;
    border-radius: 7.5px;
    &:hover {
      background-color: ${theme.colors.grey.light};
    }
  }
  
  ${({isOpen}) => !isOpen && css`
    opacity: 0;
    transform: translateY(-10px);
    transition: transform .5s ease, opacity .5s ease, background .5s ease;
  `}
`)

const LevelMenuItemWithArrow = withContextProps(isOpenContext, styled(LevelMenuItem)`
  & > *:first-child::after {
    content: '';
    background-image: url(${iconArrowRight});
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding: 0px 14px;
    background-size: 14px;
  } 
`)

const LevelContent = withContextProps(isOpenContext, styled.ul`
  height: calc(100vh - 60px);
  width: 100vw;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform .5s ease, opacity .5s ease;
  transform: translateX(100vw);

  ${({isVisited, isCurrent}) => isVisited && !isCurrent && css`
    transform: translateX(0px);
  `}

  ${({isRoot, isCurrent}) => isRoot && isCurrent && css`
    transform: translateX(0px);
  `}

  ${({isRoot, isCurrent}) => isRoot && !isCurrent && css`
  transform: translateX(-100vw);
  `}

  ${({isVisited, isCurrent, isOpen}) => !(isOpen && !(!isVisited && !isCurrent)) && css`
    opacity: 0;
    pointer-events: none;
  `}
`)

export const LevelMenuRoot = withTraversal(LevelContent);

export const Level = withTraversal(withContextProps(isOpenContext, ({label, children, enter, exit, isCurrent, isVisited}) => {
  return (
    <LevelMenuItemWithArrow>
      <button onClick={enter}>{label}</button>
      <LevelContent isCurrent={isCurrent} isVisited={isVisited}>
      <BackButton onClick={exit}>{label}</BackButton>
        {children}
      </LevelContent>
    </LevelMenuItemWithArrow>   
  )
}))

const LevelMenu = ({menu, ...props}) => {
  const createLevel = (level) => {
    return level.map(elem => {
      if(elem.children) {
        return(
          <Level key={uuid.v4()} label={elem.label}>
            {createLevel(elem.children)}
          </Level>
        )
      } else {
        return(
          <LevelMenuItem key={uuid.v4()} ><a href={elem.href}>{elem.label}</a></LevelMenuItem>
        )
      }
    })
  }

  return(
    <LevelMenuRoot isRoot {...props}>
      {createLevel(menu)}
    </LevelMenuRoot>
  )
}

export default LevelMenu