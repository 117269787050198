import React from 'react'
import styled from "styled-components"

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    z-index: 1;
  }
`

const NavMolecule = ({children, className}) => {
  return (
  <Nav className={className}>
    <ul>
      {children}
    </ul>
  </Nav>
  )
}

export default NavMolecule
