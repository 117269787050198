import React from 'react'
// import styled, {  css }  from "styled-components"
import useBreakpoints from '../hooks/use-breakpoints.hook'
import { useToggle } from '../hooks/use-toggle.hook'
import { Navbar } from '../molecules/navbar.molecule'

import { MenuBar } from './menu-bar.organism'
import { isOpenContext } from '../contexts/navigation.context'
import { Menu } from './menu.organism'

// TODO icon component with import

// TODO dynamic menu with cms
// TODO nav is missing on desktop
// TODO line height li

// TODO functions for font styles
// TODO font color
// TODO line-height

// const SecondaryMenu = (props) => {
//   return (
//     <nav {...props}>
//       <ul>
//         <li><a>Impressum</a>
//         </li>
//         <li><a>Datenschutz</a></li>
//         <li><a>AGB</a></li>
//       </ul>
//     </nav>
//   )
// }

// // TODO same styles main menu
// const SecondaryMenuTouch = styled(SecondaryMenu)`
//   padding: 20px;
//   border-top: 1px solid #D8D8E2;
//   & > ul > li {
//     opacity: 0;
//     transform: translateX(-5px);
//     transition: transform .5s ease, opacity .5s ease, width .5s ease, margin-bottom .5s ease;
//     margin-bottom: 20px;

//     & > a {
//       color: black;
//       word-break: keep-all;
//       white-space: nowrap;
//     }
//   }
//   ${props => props.$isOpen && css`
//     box-sizing: border-box;
//     background: white;
//     & > ul > li {
//       opacity: 1;
//       transform: none;
//       margin-bottom: 30px;
//     } 
//   `}
//   & > ul > li:last-child {
//     margin-bottom: 0;
//   }
// `

const NavigationTablet = (props) => {
  const [isOpen, setIsOpen] = useToggle();

  return (
    <isOpenContext.Provider value={{isOpen, setIsOpen}}>
      <Navbar isOpen={isOpen}>
        <MenuBar>
          <Menu />
        </MenuBar>
        {/* <SecondaryMenuTouch $isOpen={isOpen}></SecondaryMenuTouch> */}
      </Navbar>
    </isOpenContext.Provider>
  )
}

const Navigation = (props) => {
  const isBreakpoint = useBreakpoints();
  
  if(isBreakpoint.tablet) {
    return (
      <NavigationTablet>
      </NavigationTablet>
    );
  } else {
    return (
      <Navbar>
        <MenuBar>
          <Menu></Menu>
        </MenuBar>
      </Navbar>);
  }
}

export default Navigation;
