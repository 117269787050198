import React from 'react'
import styled, {  css }  from "styled-components"
import uuid from "uuid";
import { withTraversal } from '../hocs/with-traversal.hoc'

import iconArrowRight from '../../images/icon-arrow-right.svg'
import theme from '../../theme';

export const BarMenuItem = styled.li`
  transition: transform .5s ease .1s, opacity .5s ease .1s, background .5s ease;
  margin: 0px 10px;
  padding: 23px 0px;
  border-radius: 7.5px;
  & > *:first-child {
    font-weight: 500;
    color: ${theme.colors.grey.dark};
    word-break: keep-all;
    white-space: nowrap;
    line-height: 1.5;
    width: 100%;
    text-align: left;
    background: transparent;
    padding: 10px 10px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    transition: background .5s ease;
    border-radius: 7.5px;
    &:hover {
      background-color: ${theme.colors.grey.light};
    }
  }
`

const BarMenuItemWithArrow = styled(BarMenuItem)`
  & > *:first-child {
    padding: 10px 35px 10px 10px;
  }
  & > *:first-child::after {
    content: '';
    position: absolute;
    height: 20px;
    width: 40px;
    background-image: url(${iconArrowRight});
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 14px;
    transform: translate(-5px, -1px) rotate(90deg);
    transform-origin: center center;
  } 
`
// TODO height auto
const BarContent = styled.ul`
  height: auto;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0px;
  display: flex;
  box-sizing: border-box;
  z-index: -1;
  transition: transform .5s ease, opacity .5s ease, background .5;

  ${({isRoot}) => !isRoot && css`
    ::before {
      content: '';
      background: transparent;
      transition: background 0s;
      height: 100%;
      width: 400vw;
      transform: translateX(-100vw);
      position: absolute;
      z-index: ${({level}) => -level};
    }
  `}

  ${({isRoot, isVisited}) => !isRoot  && css`
    justify-content: center;
    max-width: 1137px;
    left: 50%;
    transform: translateX(-50%);

    ::before {
      content: '';
      background: #f1f1f5;
      border: 1px solid #D8D8E2;
      box-shadow: 0 1px 0px 0px #D8D8E2;/*, 0 4px 4px #29293033;*/
      border-bottom: none;
    }
  `}

  ${({isVisited, isCurrent}) => !isVisited && !isCurrent && css`
    opacity: 0;
    pointer-events: none;
  `}
`

export const BarMenuRoot = withTraversal(styled(BarContent)`
  position: static;
  width: unset;
  z-index: 1;
`);

export const Bar = withTraversal(({label, children, level, enter, exit, isCurrent, isVisited}) => {
  return (
    <BarMenuItemWithArrow onMouseEnter={enter} onMouseLeave={exit}>
      <span>{label}</span>
      <BarContent level={level} isCurrent={isCurrent} isVisited={isVisited}>
        {children}
      </BarContent>
    </BarMenuItemWithArrow>   
  )
})

const BarMenu = ({menu, ...props}) => {
  const createBar = (bar, level) => {
    return bar.map(elem => {
      if(elem.children) {
        return(
          <Bar level={level} key={uuid.v4()} label={elem.label}>
            {createBar(elem.children, level + 1)}
          </Bar>
        )
      } else {
        return(
          <BarMenuItem key={uuid.v4()}><a href={elem.href}>{elem.label}</a></BarMenuItem>
        )
      }
    })
  }

  return(
    <BarMenuRoot level={0} isRoot {...props}>
      {createBar(menu, 1)}
    </BarMenuRoot>
  )
}

export default BarMenu