import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from "styled-components"

const ListItem = styled.li`
  margin: 30px 15px;
  img {
    transition: transform .3s ease;
    height: 20px;
    &:hover {
      transform: translateY(-2px);
    }
  }
`

const Instagram = styled.img`
&:hover {
  filter: invert(1) invert(13%) sepia(98%) saturate(5359%) hue-rotate(327deg) brightness(83%) contrast(105%);
}
`

const Facebook = styled.img`
&:hover {
  filter: invert(1) invert(37%) sepia(51%) saturate(2076%) hue-rotate(195deg) brightness(94%) contrast(101%);
}
`

const Twitter = styled.img`
  &:hover {
    filter: invert(1) invert(48%) sepia(71%) saturate(1758%) hue-rotate(179deg) brightness(95%) contrast(97%);
  }
`

export const SocialLinks = ({className}) => {
  const data = useStaticQuery(graphql`
    query {
      instagram: file(relativePath: { eq: "logo-instagram.svg" }) {
        publicURL
      }
      facebook: file(relativePath: { eq: "logo-facebook.svg" }) {
        publicURL
      }
      twitter: file(relativePath: { eq: "logo-twitter.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <React.Fragment>
      <ListItem className={className}>
        <a className="link--img" href="https://www.instagram.com/bloomista.garden">
          <Instagram width="20" height="20" className="white-to-dark-grey"src={data.instagram.publicURL} title="Bloomista Garden Instagram" alt="Bloomista Garden Instagram" />
        </a>
      </ListItem>
      <ListItem className={className}>
        <a className="link--img" href="https://www.facebook.com/bloomista.garden">
          <Facebook width="20" height="20" className="white-to-dark-grey"src={data.facebook.publicURL} title="Bloomista Garden Facebook" alt="Bloomista Garden Facebook" />
        </a>
      </ListItem>
      <ListItem className={className}>
        <a className="link--img" href="https://twitter.com/BloomistaGarden">
          <Twitter width="20" height="20" className="white-to-dark-grey" src={data.twitter.publicURL} title="Bloomista Garden Twitter" alt="Bloomista Garden Twitter" />
        </a>
      </ListItem>
    </React.Fragment>
  )
}

export default SocialLinks
