import React, { useContext } from 'react'
import styled from "styled-components"
import { isOpenContext, isCollapsedContext } from '../contexts/navigation.context'
import useBreakpoints from '../hooks/use-breakpoints.hook'

import logo from '../../images/logo.svg'
import logoSymbol from '../../images/logo-symbol.svg'
import iconMenu from '../../images/icon-menu.svg'
import logoInstagram from '../../images/logo-instagram.svg'
import theme from '../../theme'
import useHasMounted from '../hooks/use-has-mounted.hook'

const MenuBarNav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  box-sizing: border-box;
  position: relative;
  ${theme.media.tablet`
    height: 60px;
  `}
`

const MenuIconList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${theme.media.min.tablet`
    max-width: calc(1137px + 80px);
    padding: 0px 20px;
  `}
`

const MenuIcon = styled.li`
  flex: 0;
  width: auto;
  height: 60px;
  position: relative;
  ${({isCollapsed, keep, isOpen}) => isCollapsed && !keep && !isOpen && `
  opacity: 0;
  width: 0;`};
  ${({isCollapsed, keep, isOpen}) => isCollapsed && keep && !isOpen && `transform: translate(2px, -2px);`};
  transition: opacity .25s ease, transform .5s ease, width .25s ease .25s;
  ${({isCollapsed, keep, isOpen}) => !(!isOpen && !(!isCollapsed && !keep)) && `transition: opacity .25s ease .15s, transform .5s ease, width .25s ease .1s;`};
  
  & > ul {
    top: 60px;
  }
`


const Icon = styled.img`
  width: ${({width}) => width}px;
  height: 100%;
  display: block;
  margin: 0 auto;
`

const IconButton = styled.button`
  min-width: 60px;
  background: transparent;
  height: 100%;
  display: inline-block;
  padding: 0px 20px;
`

const MenuContainerDesktop = styled.li`
  height: 100%;
`

const MenuBarTablet = ({children}) => {
  const isCollapsed = useContext(isCollapsedContext);
  const {isOpen, setIsOpen} = useContext(isOpenContext);
  const prop = {
    isOpen,
    isCollapsed
  }
  return(
    <MenuBarNav>
      <MenuIconList>
        <MenuIcon {...prop} keep>
          <IconButton onClick={setIsOpen}><Icon title="Menu" alt="Menu" src={iconMenu} width="20" height="60"/></IconButton>
          {children}
        </MenuIcon>
        <MenuIcon {...prop}>
          <IconButton as="a" href="/" title="Bloomista Blog"><Icon title="Bloomista Logo" alt="Bloomista Logo" src={logoSymbol} width="20" height="60"/></IconButton>
        </MenuIcon>
        <MenuIcon {...prop}>
          <IconButton as="a" href="https://www.instagram.com/bloomista.garden" title="Bloomista Instagram"><Icon title="Bloomista Instagram" alt="Bloomista Instagram" className="white-to-dark-grey" src={logoInstagram} width="20" height="60"/></IconButton>
        </MenuIcon>
      </MenuIconList>
    </MenuBarNav>
  )
}

const MenuBarDesktop = ({children}) => {
  return(
    <MenuBarNav>
      <MenuIconList>
        <MenuIcon style={{marginRight: 'auto'}}>
          <IconButton as="a" href="/" title="Bloomista Blog"><Icon  title="Bloomista Logo" alt="Bloomista Logo" width="130" height="60" src={logo} /></IconButton>
        </MenuIcon>
        <li style={{height: '100%'}}>
        {children}
        </li>
        <MenuIcon>
          <IconButton as="a" href="https://www.instagram.com/bloomista.garden" title="Bloomista Instagram"><Icon title="Bloomista Instagram" alt="Bloomista Instagram" className="white-to-dark-grey" src={logoInstagram} width="20" height="60"/></IconButton>
        </MenuIcon>
      </MenuIconList>
    </MenuBarNav>
  )
}

export const MenuBar = ({children, ...props}) => {
  const isBreakpoint = useBreakpoints();
  const hasMounted = useHasMounted();

  if(!hasMounted) return null;

  if(isBreakpoint.tablet) {
    return (
      <MenuBarTablet {...props}>
        {children}
      </MenuBarTablet>
    )
  } else {
    return (
      <MenuBarDesktop {...props}>
        {children}
      </MenuBarDesktop>
    )
  }
}