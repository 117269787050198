import React from 'react'
import useBreakpoints from '../hooks/use-breakpoints.hook'
import LevelMenu from '../molecules/level-menu.molecule'
import BarMenu from '../molecules/bar-menu.molecule'

const menu = [
  {label: 'Blog', href: '/'},
  {label: 'Newsletter', href: '/newsletter'},
  {label: 'Datenschutz', href: '/datenschutz'},
  {label: 'Impressum', href: '/impressum'}
]


export const Menu = ({children, ...props}) => {
  const isBreakpoint = useBreakpoints();

  if(isBreakpoint.tablet) {
    return (
      <LevelMenu menu={menu} {...props}>
        {children}
      </LevelMenu>
    )
  } else {
    return (
      <BarMenu menu={menu} {...props}>
        {children}
      </BarMenu>
    )
  }
}

// return (
//   <LevelMenu {...props}>
//     <Level label="Shop">
//         <Level label="Test">
//             <LevelMenuItem><a>Community</a></LevelMenuItem>
//             <LevelMenuItem><a>Community</a></LevelMenuItem>
//             <LevelMenuItem><a>Community</a></LevelMenuItem>
//         </Level>
//         <LevelMenuItem><a>Community</a></LevelMenuItem>
//         <LevelMenuItem><a>Community</a></LevelMenuItem>
//         <Level label="Test2">
//             <LevelMenuItem><a>Community2</a></LevelMenuItem>
//             <LevelMenuItem><a>Community</a></LevelMenuItem>
//             <LevelMenuItem><a>Community</a></LevelMenuItem>
//         </Level>
//     </Level>
//     <LevelMenuItem><a>Community</a></LevelMenuItem>
//     <LevelMenuItem><a>Über Bloomista</a></LevelMenuItem>
//     <LevelMenuItem><a>Kontakt</a></LevelMenuItem>
//   </LevelMenu>
// )